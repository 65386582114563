@import './variables.scss';

::ng-deep dss-form-field, .dss-form-field{
    .dss-form-field {
        input[class*=ng-valid]:not(.ng-pristine){
            border: solid 1px $super-dark-gray !important;
        }
    }

    input[class*=ng-valid]:not(.ng-pristine){
        border: solid 1px $super-dark-gray !important;
    }

    &.dss-form-field--error{
        input{
            border: solid 1px $light-red !important;
        }
        input[class*=ng-valid]:not(.ng-pristine){
            border: solid 1px $light-red !important;
        }
    }

}
