.dss-drawer{

    .dss-drawer__content{

        .dss-drawer__header{
            font-weight: 600;
        }

        .dss-drawer__body{

            mob-button{    
               
                .dss-button{

                    margin: 0 auto;
                    display: block;
                    
                    @media screen and (max-width:768px){
                        width: 100%;
                        margin: 0 auto;
                        display: block;
                    }
                }
            }

        }
    }

}
