@import './variables.scss';


.form{

    padding: 0;
    margin-top: 2rem;

    &-button{
        background: $red;
        display: block;
        padding: 1rem;
        text-align: center;
        color: $white;
        border-radius: 5px;
        font-size: 1.6rem;
        min-width: 200px;
    }

    &-field{

        &-label{
            position: relative;
            background: $white;
            top: 2.3rem;
            display: inline-block;
            left: 1.2rem;
            padding: 0 0.6rem;
            font-size: 1.2rem;
        }

        &-input{
            border-radius: 5px;
            border:solid 1px rgb(180, 175, 175);
            padding: 1rem;
            display: block;
            width: 100%;
            margin: 1rem 0;
        }

        &.error{
            label{
                color:$red;
            }
            .error-message{
                color:$red;
                margin-bottom: 1rem;
                font-size: 1.2rem;
            }

            .form-field-input {
                color:$red;
                border-color: $red;
                margin-bottom: 0;
            }
        }
    }

    .dss-form-field__error-message, .dss-form-field__hint{
        margin-top: 0.4rem;
    }

}
