@import './variables.scss';

/* width */
::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background:$light-gray;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $dark-gray;
    border-radius: 1rem;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $super-dark-gray;
  }
