@import "~swiper/swiper-bundle";
@import "assets/scss/boostrap.scss";
@import "assets/scss/dss-accordion.scss";
@import "assets/scss/dss-drawer.scss";
@import "assets/scss/dss-form.scss";
@import "assets/scss/dss-list.scss";
@import "assets/scss/form.scss";
@import "assets/scss/icons.scss";
@import "assets/scss/mixins.scss";
@import "assets/scss/page.scss";
@import "assets/scss/reset.scss";
@import "assets/scss/scroll.scss";

::ng-deep .dss-form-field {
  background-color: #990000;
  .__input {
    font-size: 22px !important;
  }
}
a:focus {
  text-decoration: underline;
}

*,
body {
  font-family: "Open Sans", sans-serif;
  font-display: swap;
  box-sizing: inherit;
  outline: 0;
}

h1 {
  font-weight: 300;
}

h2 {
  font-weight: 100;
  color: #999;
}

/* You can add global styles to this file, and also import other style files */

.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.jc-flex-end {
  justify-content: flex-end;
}

.jc-space-between {
  justify-content: space-between;
}

.ai-center {
  align-items: center;
}

@media (max-width: 767.98px) {
  .d-none-sm {
    display: none;
  }
}

@media (min-width: 768px) {
  .d-none-lg {
    display: none;
  }

  .d-flex-lg {
    display: flex;
  }
}

.mob-bg-blue {
  background-color: #eef6f8;
}

.mob-bg-boston {
  background-color: #cc0000;
}

.mob-bg-soft-white {
  background-color: #fbfbfb;
}

.mob-boston {
  color: #cc0000 !important;
}

.mob-white {
  color: white !important;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.ta-righ {
  text-align: -webkit-right;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-64 {
  margin-bottom: 64px !important;
}

@media (min-width: 768px) {
  .mb-16 {
    margin-bottom: 16px !important;
  }

  .mb-24 {
    margin-bottom: 24px !important;
  }

  .mb-40 {
    margin-bottom: 40px !important;
  }

  .mb-48 {
    margin-bottom: 48px !important;
  }

  .mb-80 {
    margin-bottom: 80px !important;
  }
}

.mob-btn {
  width: 100%;
  text-align: center;
  color: white;
  // padding: 16px;
  font-size: 16px;
  line-height: 24px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 400ms ease;
  border: 0;
}

.mob-btn-2 {
  width: 100%;
  text-align: center;
  color: white;
  padding: 16px;
  font-size: 16px;
  line-height: 24px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 400ms ease;
  border: 0;
}

.mob-btn-primary {
  background-color: #cc0000;
}

.mob-btn-secondary {
  background-color: white;
  color: #cc0000;
}

.mob-btn-primary:hover {
  background-color: #990000;
}

.mob-btn-secondary:hover {
  background-color: #ccc;
}

.mob-btn:focus {
  outline: none;
}

.mob-btn-1 {
  font-size: 18px;
  line-height: 32px;
  padding: 8px 16px;
  min-width: 170px;
}

.mob-btn-3 {
  width: auto;
  font-size: 16px;
  line-height: 18px;
  padding: 8px 16px;
}

@media (min-width: 768px) {
  .mob-btn {
    width: auto;
  }

  .mob-btn-2 {
    min-width: 200px;
  }

  .mob-btn-4 {
    min-width: 280px;
  }

  .mob-btn-5 {
    max-width: 280px;
    min-width: 280px;
    height: 56px;
  }
}

.mob-title-1 {
  font-size: 22px;
  line-height: 1.5;
  color: #222;
}

@media (min-width: 768px) {
  .mob-title-1 {
    font-size: 40px;
  }
}

.mob-text-1 {
  font-size: 15px;
  line-height: 1.5;
  color: #222;
}

@media (min-width: 768px) {
  .mob-text-1 {
    font-size: 22px;
    color: #767676;
  }
}

.mob-title-3 {
  font-size: 24px;
  line-height: 1.5;
  color: #222;
}

@media (min-width: 768px) {
  .mob-title-3 {
    font-size: 36px;
  }
}

.mob-title-2 {
  font-size: 18px;
  line-height: 1.5;
  color: #222;
}

@media (min-width: 768px) {
  .mob-title-2 {
    font-size: 22px;
  }
}

.mob-text-2 {
  font-size: 14px;
  line-height: 1.5;
  color: #444;
}

@media (min-width: 768px) {
  .mob-text-2 {
    font-size: 16px;
  }
}

.mob-text-4 {
  font-size: 36px;
  font-weight: 300;
  line-height: 1.24;
  letter-spacing: -1.5px;
  color: #000;
}

@media (min-width: 767.98px) {
  .min-w-200 {
    min-width: 200px;
  }
}

@media (max-width: 767.98px) {
  .min-w-200 {
    min-width: 100%;
  }
}

@media (max-width: 767.98px) {
  .margin-bottom {
    margin-bottom: 1.5rem !important;
  }
}

.mob-small {
  font-size: 0.5em;
}

.mob-text-5 {
  font-size: 18px;
  line-height: 1.5;
  color: #222;
}

.mob-text-6 {
  font-size: 22px;
  line-height: 26px;
  color: #cc0000;
}

.mob-text-7 {
  font-size: 14px;
  line-height: 1.5;
  color: #444;
}

@media (min-width: 768px) {
  .mob-text-7 {
    font-size: 18px;
    color: #222;
  }
}

.mob-text-8 {
  font-size: 14px;
  line-height: 1.5;
  color: #444;
}
.inline-content {
  display: inline;
}
.mob-hr {
  width: 100%;
  height: 1px;
  background-color: #d8d8d8;
}

.mob-box-container {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .mob-box-container {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .mob-spacing {
    padding-top: 0;
  }
}

@media (min-width: 768px) {
  .mob-items-container {
    display: flex;
    align-items: normal;
  }
}

.mob-items-container .mob-item {
  border-radius: 4px;
  box-shadow: 0 0 16px rgba(34, 34, 34, 0.24);
  background-color: #fff;
  margin-bottom: 24px;
  padding: 24px;
}

@media (min-width: 768px) {
  .mob-items-container .mob-item {
    padding: 40px;
    margin-bottom: 0;
    margin-right: 32px;
    flex: 1 1 0;
  }
}

.mob-items-container .mob-item:last-child {
  margin-bottom: 0;
  margin-right: 0;
}

.mob-loader {
  opacity: 0;
  visibility: 0;
  pointer-events: none;
  background-color: white;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.mob-loader > div {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mob-loader > div > div {
  position: absolute;
  border-radius: 50%;
  border: 3px solid #e1e1e1;
  width: 100%;
  height: 100%;
}

.mob-loader > div .mob-loader-circle-red {
  border-top-color: #cc0000;
  transform: rotate(-7200deg);
  transition: transform 20s linear;
}

.mob-loader.mob-loading {
  opacity: 1;
  visibility: visible;
}

.mob-loader.mob-loading .mob-loader-circle-red {
  transform: rotate(0);
}

@media (max-width: 767.98px) {
  .mob-input {
    margin-bottom: 16px;
  }
}

@media (min-width: 768px) {
  .mob-input {
    margin-right: 16px;
  }
}

::ng-deep .dss-form-field {
  background-color: #990000 !important;
  &__input {
    background-color: rgba(255, 99, 71, 0) !important;
  }
}

.mb-input {
  font-size: 22px;
}

.mob-input .mob-border {
  padding: 16px;
  border: 1px solid #767676;
  border-radius: 4px;
  position: relative;
}

.mob-input .mob-border label,
.mob-input .mob-border input {
  font-size: 22px;
  line-height: 38px;
  color: #444;
}

.mob-input .mob-border label {
  background: white;
  transition: all 0.3s linear;
  pointer-events: none;
  position: absolute;
  left: 12px;
  top: 8px;
  padding: 0 4px;
}

.mob-input .mob-border label.mob-top {
  font-size: 16px;
  line-height: 1.5;
  top: -12px;
  left: 8px;
}

.mob-input .mob-border input {
  border: 0;
  width: 100%;
}

.mob-input .mob-error-info {
  font-size: 16px;
  line-height: 1.5;
  color: #cc0000;
  transition: all 0.3s linear;
  max-height: 0;
  overflow: hidden;
}

.mob-input.mob-error .mob-border {
  border-color: #cc0000;
}

.mob-input.mob-error .mob-error-info {
  margin-top: 12px;
  max-height: 30px;
}

.mob-input.mob-over-red .mob-border {
  border-color: white;
}

.mob-input.mob-over-red .mob-border label,
.mob-input.mob-over-red .mob-border input {
  background: #cc0000;
  color: white;
}

.mob-input.mob-over-red .mob-error-info {
  color: #ccc;
}

.mob-input.mob-over-red.mob-error .mob-border {
  border-color: #ccc;
}

.mob-beneficios .mob-items-container > .mob-item {
  border-left: solid 2px #cc0000;
  display: flex;
}

@media (min-width: 768px) {
  .mob-beneficios .mob-items-container > .mob-item {
    min-height: auto !important;
  }
}

.mob-beneficios .mob-items-container > .mob-item > .mob-icon {
  margin-right: 24px;
  width: auto;
}

@media (min-width: 768px) {
  .mob-beneficios .mob-items-container > .mob-item > .mob-icon {
    margin-right: 32px;
  }
}

.mob-beneficios .mob-items-container > .mob-item > .mob-icon > .mob-circle-red {
  padding: 16px;
  border-radius: 100px;
  background-color: #cc0000;
}

@media (min-width: 768px) {
  .mob-beneficios
    .mob-items-container
    > .mob-item
    > .mob-icon
    > .mob-circle-red {
    padding: 24px;
  }
}

.mob-beneficios
  .mob-items-container
  > .mob-item
  > .mob-icon
  > .mob-circle-red
  > svg {
  width: 40px;
  height: 40px;
  display: block;
}

@media (min-width: 768px) {
  .mob-beneficios
    .mob-items-container
    > .mob-item
    > .mob-icon
    > .mob-circle-red
    > svg {
    width: 56px;
    height: 56px;
  }
}

.mob-beneficios-app .mob-items-container > .mob-item {
  padding: 0;
}

@media (min-width: 768px) {
  .mob-beneficios-app .mob-items-container > .mob-item {
    margin-right: 32px;
  }
}

.mob-beneficios-app .mob-items-container > .mob-item > .mob-image > img {
  width: 100%;
}

.mob-beneficios-app .mob-items-container > .mob-item > .mob-info {
  padding: 24px;
}

.mob-pacotes {
  background-color: #fbfbfb;
}
.mob-slider-container {
  max-width: 375px;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.mob-pacotes .mob-pacotes-container {
  margin-bottom: 64px;
}

@media (min-width: 798px) {
  .mob-pacotes .mob-pacotes-container {
    margin-bottom: 40px;
  }
}

.mob-pacotes .mob-pacotes-container .mob-pacote {
  padding: 20px 15px 24px;
  height: 100%;
}

.mob-pacotes .mob-pacotes-container .mob-pacote.mob-digital .mob-btn {
  display: block;
}

.mob-pacotes .mob-pacotes-container .mob-pacote > div {
  border-radius: 4px;
  box-shadow: 0 0 16px rgba(34, 34, 34, 0.24);
  background-color: #fff;
  position: relative;
  padding-top: 34px;
}

.mob-pacotes .mob-pacotes-container .mob-pacote > div > .mob-btn {
  display: none;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mob-pacotes .mob-pacotes-container .mob-pacote > div > .mob-title-2 {
  margin-bottom: 16px;
}

.mob-pacotes
  .mob-pacotes-container
  .mob-pacote
  > div
  > .mob-pacote-info-container {
  padding: 32px 18px 32px;
}

@media (min-width: 768px) {
  .mob-pacotes
    .mob-pacotes-container
    .mob-pacote
    > div
    > .mob-pacote-info-container {
    padding: 24px;
  }
}

.mob-pacotes
  .mob-pacotes-container
  .mob-pacote
  > div
  > .mob-pacote-info-container
  > .mob-info {
  display: flex;
  margin-bottom: 24px;
}

.mob-pacotes
  .mob-pacotes-container
  .mob-pacote
  > div
  > .mob-pacote-info-container
  > .mob-info:last-child {
  margin-bottom: 0;
}

.mob-pacotes
  .mob-pacotes-container
  .mob-pacote
  > div
  > .mob-pacote-info-container
  > .mob-info
  > .mob-icon {
  width: 20px;
  height: 20px;
  margin-right: 18px;
  img {
    min-width: 20px;
    min-height: 20px;
  }
}

.mob-servicos {
  border-radius: 4px;
  border: solid 1px #ccc;
  background-color: #fff;
  padding: 40px 24px 24px;
}

@media (min-width: 768px) {
  .mob-servicos {
    padding: 56px 15vw;
  }
}

@media (min-width: 768px) {
  .mob-cartaos .col-12 {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
}

@media (min-width: 768px) {
  .mob-cartaos .col-12 > .mob-image {
    margin-left: 10vw;
    margin-right: 8.5vw;
  }
}

.mob-cartaos .col-12 > .mob-image img {
  width: 280px;
}

@media (min-width: 768px) {
  .mob-cartaos .col-12 > .mob-image img {
    width: 400px;
  }
}

.mob-abrir-conta .col-12 {
  display: flex;
  align-items: center;
}

.mob-abrir-conta .col-12 .mob-image {
  margin-right: 5vw;
}

.mob-abrir-conta .col-12 .mob-infos > .mob-info {
  margin-bottom: 56px;
  display: flex;
  align-items: center;
}

.mob-abrir-conta .col-12 .mob-infos > .mob-info:last-child {
  margin-bottom: 0;
}

.mob-abrir-conta .col-12 .mob-infos > .mob-info > .mob-num {
  width: 48px;
  height: 48px;
  text-align: center;
  border-radius: 50%;
  border: solid 1px #ccc;
  margin-right: 24px;
  padding: 10px;
  flex-shrink: 0;
}

@media (min-width: 768px) {
  .mob-abrir-conta .col-12 .mob-infos > .mob-info > .mob-num {
    margin-right: 32px;
  }
}

.mob-legal .mob-text-2,
.mob-legal a {
  color: #c7c7c7;
}

.mob-beneficios-app {
  display: none;
}

form {
  border-top: 0px solid #f00 !important;
}
