.dss-accordion {
  .dss-accordion__item-title {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-right: 2rem;

    @media screen and (max-width: 768px) {
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }

  .dss-accordion__item {
    max-height: unset;

    &-content {
      p {
        div {
          font-weight: 300;
        }
      }
    }
  }

  .dss-accordion__item-header {
    &:hover {
      background-color: unset;
    }

    h1 {
      font-weight: 400;
    }
  }
}
