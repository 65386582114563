//colors
$red: rgb(204, 0, 0);
$light-red:rgb(241, 76, 76);
$white: rgb(255, 255, 255);
$white-light: rgba(255, 255, 255, 0.7);
$black: rgb(34, 34, 34);
$blue: rgb(0, 173, 255);
$light-blue: rgb(143, 190, 228);
$gray: rgb(226, 226, 226);
$light-gray: rgb(248, 248, 248);
$dark-gray:rgb(172, 171, 171);
$super-dark-gray:rgb(118, 118, 118);
$green: rgb(115,158,65);

$color-white-100: #fff;

$color-gray-100: #edf1f4;
$color-gray-90: #ddd;
$color-gray-80: #c0bdb8;
$color-gray-50: #727165;
$color-gray-40: #7e8684;

$color-red-30: #751d2c;
$color-red-50: #d31b1b;
$color-red-60: #ec0000;
$color-red-70: #c84541;

$color-blue-100: #f7fafb;


// spacing
$spacing: 1rem;

$base-radius: 4px;

$border-gray: solid 1px $gray;
$base-box-shadow:  0 2px 10px 0 rgba(68, 68, 68, 0.2);

$font-size-10px: 0.625rem;
$font-size-12px: 0.75rem;
$font-size-14px: 0.875rem;
$font-size-16px: 1rem;
$font-size-18px: 1.125rem;
$font-size-20px: 1.25rem;
$font-size-22px: 1.375rem;
$font-size-24px: 1.5rem;
$font-size-32px: 2rem;
