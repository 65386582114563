@import '../scss/variables.scss';

.icon{

    &-logo{
        position: relative;
        transition: fill .3s ease;
        display: flex;
        height: 3rem;
        max-width: 8rem;
        margin-top: 0.5rem;
    }
}
