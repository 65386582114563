@import './variables.scss';

.opf-page{

    padding: 4rem 4rem 0rem 4rem;

    @media screen  and (max-width: 768px){
        margin-bottom: 6rem;
    }

    &__header{

        &__icon{
            font-size: 2.5rem;
        }

        span[class*="dss-icon"]{
            color: $blue;
            font-size: 3.5rem;
        }

        // &__title{
        //     font-size: 1.4rem;
        //     font-weight: 400;
        //     line-height: 2rem;
        // }
        &__subtitle{
            margin-top: 1.5rem;
            font-size: 1rem;

            @media screen  and (max-width: 768px){
                font-size: 1.2rem;
            }
        }
    }
    &__body{
        margin-bottom: 3rem;
    }

    &__footer{
        text-align: center;
        padding-top: 1.2rem;
        border-top: solid 2px $gray;
        padding-bottom: 1.2rem;
        background-color: $white;

        @media screen  and (max-width: 768px){
            //border-width: 0px;

            position: unset;
        }

        button{
            @media screen  and (max-width: 768px){
                width: 100%;
            }
        }

    }

    @media screen  and (max-width: 768px){
        padding: 1rem;
    }

    &--is-loading{
        display: none;
    }

    &-centered{
        .opf-page__header{
            text-align: center;
            &__icon{
                font-size: 3.5rem;
            }
            &__title{
                font-size: 1.5rem;
                font-weight: 600;
                line-height: 2.5rem;
                text-align: center;
            }
            &__subtitle{
                text-align: center;
                margin-left: 0.5rem;
                margin-right: 0.5rem;
            }
        }
    }
}

